.container1 {
    max-width: 400px;
    padding: 16px;
    background-color: white;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

@media (max-width: 480px) {
    .container1 {
        left: 10%;
        top: 10%;
        margin-left: 5%;
        transform: translate(-10%, -10%);
    }
}